<template>
  <div class="p-grid card">
    <div class="p-col-12">
      <p-toolbar class="p-mb-4">
        <template #left>
          <h5 class="p-m-0">Minha Equipe</h5>
        </template>

        <template #right>
          <span class="p-d-inline-flex p-mr-2 p-mb-2">
            <p-inputtext
              v-model="dataParams.filter"
              @keypress.enter="find"
              placeholder="Pesquisar..."
            />
            <p-button icon="pi pi-search" @click="find" />
          </span>
        </template>
      </p-toolbar>

      <p-datatable
        ref="dt"
        :value="itens"
        v-model:selection="selectedItens"
        data-key="id"
        :loading="loading"
        :first="0"
        :lazy="true"
        :paginator="true"
        :rows="dataParams.rows"
        :totalRecords="dataParams.totalRecords"
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        :rowsPerPageOptions="[25, 50, 200, 500]"
        currentPageReportTemplate="{first} - {last} de {totalRecords}"
        @page="onPage"
        @sort="onSort"
        @filter="onFilter"
        responsiveLayout="scroll"
        breakpoint="960px"
        stripedRows
      >
        <template #empty>
          <div class="p-text-center p-mt-5 p-mb-5">Nenhum registro encontrado!</div>
        </template>

        <p-column
          field="nivel"
          header="Nível"
          sortable
          style="max-width: 70px"
        />
        <p-column
          field="parent.code"
          header="Patrocinador"
          :sortable="false"
          style="max-width: 100px"
        >
          <template #body="{ data }">
            <a
              style="cursor: pointer"
              @click="userView.show(data.parent.id, 'Patrocinador(a)')"
            >{{ data.parent.code }}</a>
          </template>
        </p-column>
        <p-column field="code" header="ID" :sortable="true" />
        <p-column field="name" header="Nome" sortable style="min-width: 250px">
          <template #body="{ data }">
            <div class="p-d-flex p-ai-center">
              <x-avatar
                class="p-mr-2"
                style="
                  min-width: 30px;
                  width: 30px;
                  min-height: 30px;
                  height: 30px;
                "
                :user="data"
              />
              <a style="cursor: pointer" @click="userView.show(data.id)">
                {{
                  data.name
                }}
              </a>
            </div>
          </template>
        </p-column>
        <p-column
          field="perfil.nome"
          header="Plano Atual"
          :sortable="false"
          style="min-width: 100px"
        >
          <template #body="{ data }">{{ data.perfil?.nome || "-" }}</template>
        </p-column>
        <p-column
          field="cp.pgv"
          header="Pontos Válidos Ciclo"
          sortable
          style="text-align: right"
        >
          <template #body="{ data }">{{ data.pgv || "0" }}</template>
        </p-column>
        <p-column
          field="ativado"
          header="Ativo Ciclo"
          sortable
          style="min-width: 100px"
        >
          <template #body="{ data }">{{ data.ativado ? "Sim" : "Não" }}</template>
        </p-column>
        <p-column
          field="ativado_rede"
          header="Ativo Rede"
          :sortable="true"
          style="min-width: 90px"
        >
          <template #body="{ data }">{{ data.ativadoRede ? "Sim" : "Não" }}</template>
        </p-column>
        <p-column
          field="pessoa.telefone"
          header="Exp.Rede"
          :sortable="true"
          style="min-width: 120px"
        >
          <template #body="{ data }">{{ frtData(data.expRede) }}</template>
        </p-column>
        <p-column
          field="e.created_at"
          header="Cadastrado"
          :sortable="true"
          style="min-width: 150px"
        >
          <template
            #body="{ data }"
          >{{ new Date(data.createdAt).toLocaleString() }}</template>
        </p-column>
        <!-- <Column field="created_at" header="Expiração" :sortable="true"></Column> -->
        <!-- <Column field="graduacao" header="Graduação" :sortable="true"></Column> -->
      </p-datatable>
    </div>
  </div>

  <x-user-view
    v-model:visible="userView.visible"
    :title="userView.title"
    :id="userView.id"
  ></x-user-view>
</template>

<script>
import { getService } from "@/services/UserService";
import { ref, watch } from "@vue/runtime-core";
import { useShowUser } from "@/components/UserView.vue";

export default {
  data() {
    return {
      itens: null,
      item: {},
      selectedItens: null,

      loading: false,

      dataParams: {
        rows: 25,
        page: 0,
        sortField: "e.id",
        sortOrder: 1,
        totalRecords: 0,
        filter: "",
      },
    };
  },
  setup() {
    const userView = useShowUser();

    return {
      userView,
    };
  },
  mounted() {
    this.find();
  },
  methods: {
    async find() {
      this.loading = true;
      try {
        const result = await getService().findEquipe({
          q: this.dataParams.filter,
          first: this.dataParams.rows,
          page: this.dataParams.page + 1,
          sortField: this.dataParams.sortField,
          sortOrder: this.dataParams.sortOrder > 0 ? "ASC" : "DESC",
        });
        this.itens = result.data;
        this.dataParams.totalRecords = result.paginatorInfo.total;
      } finally {
        this.loading = false;
      }
    },
    onPage(evt) {
      this.dataParams.rows = evt.rows;
      this.dataParams.page = evt.page;
      this.find();
    },
    onFilter(evt) {
      console.log("onFilter", evt);
    },
    onSort(evt) {
      this.dataParams.sortField = evt.sortField;
      this.dataParams.sortOrder = evt.sortOrder;
      this.find();
    },
    frtData(data) {
      return data ? data.replace(/(\d+)-(\d+)-(\d+)/, "$3/$2/$1") : "";
    },
  },
};
</script>

<style scoped>
::v-deep(.p-datatable .p-datatable-thead > tr > th) {
  padding: 0.7em 0.5em;
}
/* @media screen and (max-width: 960px) {
  ::v-deep(.p-datatable .p-datatable-tbody > tr > td) {
    padding: 7px;
  }
} */
</style>